import { useAtom } from "jotai";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ActivityHandler } from "./components/ActivityHandler";
import { SearchParamsFetcher } from "./components/ParamsFetcher";
import { ProjectNav } from "./components/ProjectNav";
import { Sidebar } from "./components/Sidebar";
import { TextList } from "./components/TextList";
import { WebsocketsHandler } from "./components/WebsocketsHandler";
import { projectIdAtom } from "./stores/Project";

function ProjectPage() {
  const params = useParams<{ id: string }>();
  const [projectId, setProjectId] = useAtom(projectIdAtom);

  useEffect(function initProjectIdAtom() {
    const projectId = params.id;

    if (!projectId) {
      throw new Error("Project ID in URL is required");
    }

    setProjectId(projectId);
  }, []);

  return (
    <div style={{ width: "calc(100% - 300px)", padding: 50 }}>
      {projectId && <WebsocketsHandler projectId={projectId} />}
      <SearchParamsFetcher />
      {projectId && <ActivityHandler />}
      <h1>Project Page</h1>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <ProjectNav />
        <TextList />
        <Sidebar />
      </div>
    </div>
  );
}

export { ProjectPage };
