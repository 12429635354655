import { getProjectActivity } from "@/http/changesTyped";
import { getTextItemActivity } from "@/http/comp";
import { IFActualChange } from "@shared/types/ActualChange";
import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { projectIdAtom } from "./Project";
import { selectedTextItemIdsAtom } from "./SelectionStore";

// Will need to be increased for product, 5 is for testing
export const PROJECT_ACITIVTY_FETCH_COUNT = 5;

export const projectActivityAtom = atom<{ activity: IFActualChange[] | null; skip: number }>({
  activity: null,
  skip: 0,
});

// We use a write atom here instead of an async derived atom because doing so
// would cause the "read" to become not-pure since projectActivityAtom grows
// each time more activity is fetched
// I Jotai "read" must be pure, so we can't use a derived atom
export const fetchMoreProjectActivityAtom = atom(null, async (get, set) => {
  const projectId = get(projectIdAtom);

  if (!projectId) {
    return;
  }

  const projectActivity = get(projectActivityAtom);
  const [request] = getProjectActivity({
    projectId: projectId,
    skip: projectActivity.skip,
    limit: PROJECT_ACITIVTY_FETCH_COUNT,
  });

  const result = (await request).data.info;
  set(projectActivityAtom, {
    activity: [...(projectActivity.activity || []), ...result],
    skip: projectActivity.skip + PROJECT_ACITIVTY_FETCH_COUNT,
  });
});

export const refreshProjectActivityAtom = atom(null, async (get, set) => {
  const projectId = get(projectIdAtom);

  if (!projectId) {
    return;
  }

  const projectActivity = get(projectActivityAtom);
  const [request] = getProjectActivity({
    projectId: projectId,
    skip: projectActivity.skip - PROJECT_ACITIVTY_FETCH_COUNT,
    limit: (projectActivity.activity || []).length,
  });

  const result = (await request).data.info;
  set(projectActivityAtom, { activity: result, skip: projectActivity.skip });
});

export const projectActivityInitializeEffectAtom = atomEffect((get, set) => {
  const projectId = get(projectIdAtom);

  if (!projectId) {
    return;
  }

  set(fetchMoreProjectActivityAtom);
});

export const HARDCODED_TEXT_ITEM = "66ccdd729b36f0c82fdadf99";

// This atom is used to trigger the fetching of text item activity
// When this updates, the textItemActivityAtom will start fetching
// Because this is an async atom, React will pick up the pending state
// and use Suspense to show a loading state
const textItemActivityTriggerAtom = atom(0);

export const refreshTextItemActivityAtom = atom(null, async (get, set) => {
  set(textItemActivityTriggerAtom, get(textItemActivityTriggerAtom) + 1);
});

export const textItemActivityAtom = atom<Promise<IFActualChange[]>>(async (get) => {
  get(textItemActivityTriggerAtom);
  const selectedTextItems = get(selectedTextItemIdsAtom);

  if (selectedTextItems.length !== 1) {
    return [];
  }

  let result: IFActualChange[] = [];

  if (selectedTextItems[0] === HARDCODED_TEXT_ITEM) {
    const [request] = getTextItemActivity({ textItemId: selectedTextItems[0] });

    result = (await request).data;
  }

  return result;
});
