import classNames from "classnames";
import React from "react";
import style from "./index.module.css";

interface IProps extends React.HTMLAttributes<HTMLSpanElement> {
  children: React.ReactNode;
  className?: string;
  size?: "base" | "small" | "micro";
}

// TODO: typesafe text variations for different headers, font weights, etc
const Text = React.forwardRef<HTMLSpanElement, IProps>(function Text(props, ref) {
  const { children, ...rest } = props;

  const size = props.size || "base";

  return (
    <span
      {...rest}
      className={classNames(style.TextWrapper, props.className, {
        [style[`size-${size}`]]: size,
      })}
      data-testid="text"
      ref={ref}
    >
      {children}
    </span>
  );
});

export default Text;
