import { RichText } from "@shared/types/TextItem";
import { Types } from "mongoose";
import { z } from "zod";
import { TEXT_ITEM_STATUSES } from "./TextItem";
import { FrontendSchema } from "./lib";

export const VARIABLE_ACTUAL_CHANGE_TYPES = {
  VARIABLE_CREATION: "variable-creation",
  VARIABLE_UPDATE: "variable-update",
  VARIABLE_DELETION: "variable-deletion",
  VARIABLE_NAME_UPDATE: "variable-name-update",
  VARIABLE_FOLDER_CREATION: "variable-folder-creation",
  VARIABLE_FOLDER_DELETION: "variable-folder-deletion",
  VARIABLE_FOLDER_UPDATE: "variable-folder-update",
  VARIABLE_MOVED_TO_FOLDER_IN_BULK_OPERATION: "variable-move-to-folder-in-bulk-operation",
  VARIABLE_MOVED_TO_FOLDER: "variable-move-to-folder",
};

export const PLURAL_CHANGE_TYPES = {
  PLURAL_ADDED: "plural-added",
  PLURAL_REMOVED: "plural-removed",
  PLURAL_EDITED: "plurals-edited",
};

export const BRANCH_CHANGE_TYPES = {
  BRANCH_UPDATED: "branch-updated",
  BRANCH_ARCHIVED: "branch-archived",
  BRANCH_MERGED: "branch-merged",
  BRANCH_DISCARDED: "branch-discarded",
};

export const DRAFTING_CHANGE_TYPES = {
  CONNECT_PROJECT: "connect-project",
  DRAFT_GROUP_CREATED: "draft-group-created",
  GROUP_CONNECTED: "group-connected",
  GROUP_UNLINKED: "group-unlinked",
  GROUP_DELETED: "group-deleted",
  GROUP_LINKING_ENABLED: "group-linking-enabled",
  GROUP_REVERTED_TO_DRAFT: "group-reverted-to-draft",
  ADD_TEXT_ITEM_TO_DRAFT_GROUP: "add-text-item-to-draft-group",
  DELETE_TEXT_ITEM_FROM_DRAFT_GROUP: "delete-text-item-from-draft-group",
};

export const AI_USAGE_TYPES = {
  AUTOMATED_COMPONENT_NAMING: "automated-component-naming",
  AI_EDITOR: "ai-editor",
};

export const ZComponentCreationType = z.enum([
  "draft-modal",
  "figma-variable",
  "bulk-import",
  "bulk-import-csv",
  "bulk-import-api",
  "text-item",
  "create-suggestion",
]);
export type ComponentCreationType = z.infer<typeof ZComponentCreationType>;

export const getDittoComponentIdByComponentCreationType = (type: ComponentCreationType) => {
  if (type === "figma-variable") {
    return "change-items.component-creation.via-figma-variables";
  }

  if (type === "bulk-import") {
    return "change-items.component-creation.via-string-import";
  }

  if (type === "bulk-import-csv") {
    return "change-items.component-creation.via-csv-import";
  }

  if (type === "bulk-import-api") {
    return "change-items.component-creation.via-api-import";
  }

  return "change-items.component-creation.default";
};

export const ENTRY_TYPES = {
  DELETE: "delete",
  IMPORT: "import",
  EDIT: "edit",
  EDIT_FROM_SUGGESTION: "edit-from-suggestion",
  HIDE_COMP: "hide-comp",
  UNHIDE_COMP: "unhide-comp",
  MULTI_HIDE: "multi-hide-comp",
  MULTI_UNHIDE: "multi-unhide-comp",
  GROUP_RENAMED: "group-renamed",
  FRAME_ADDED: "frame-added",
  FRAME_REMOVED: "frame-removed",
  DEV_MODE_ON: "dev-mode-on",
  BLOCK_CREATED: "block-created",
  BLOCKS_CREATED: "blocks-created",
  DITTO_BLOCK_CREATED: "ditto-blocks-created",
  DITTO_BLOCK_UPDATED: "ditto-block-updated",
  DITTO_BLOCK_DELETED: "ditto-block-deleted",
  BLOCK_APIID_EDIT: "block-apiID-edit",
  WS_APIID_EDIT: "ws-apiID-edit",
  // edits done in bulk should not show up at the comp library level,
  // but should show up for individual components
  WS_APIID_EDIT_BULK: "ws-apiID-edit-bulk",
  TEXT_APIID_EDIT: "text-apiID-edit",
  FRAME_APIID_EDIT: "frame-apiID-edit",
  SYNC_FROM_API: "sync-from-api",
  DUPES_EDIT: "dupes-edit",
  DUPES_STATUS: "dupes-status",
  COMP_ASSIGNED: "comp-assigned",
  MULTI_COMP_ASSIGNED: "multi-comp-assigned",
  WS_COMP_ASSIGNED: "ws-comp-assigned",
  MULTI_WS_COMP_ASSIGNED: "multi-ws-comp-assigned",
  STATUS: "status",
  SYNC: "sync", // Now deprecated in favor of lastSync field in ActualComponent
  WS_COMP_STATUS: "ws-comp-status",
  WS_COMP_EDIT: "ws-comp-edit",
  WS_COMP_EDIT_BULK: "ws-comp-edit-bulk",
  WS_COMP_EDIT_NOTES: "ws-comp-edit-notes",
  WS_COMP_EDIT_TAGS: "ws-comp-edit-tags",
  WS_COMP_RENAME: "ws-comp-rename",
  WS_COMP_MULTI_RENAME: "ws-comp-multi_rename",
  WS_COMP_ATTACH: "ws-comp-attach",
  WS_COMP_DETACH: "ws-comp-detach",
  DUPES_WS_COMP_DETACH: "dupes-ws-comp-detach",
  WS_COMP_SWAP: "ws-comp-swap",
  WS_COMP_ADD_VARIANT: "ws-comp-add-variant",
  WS_COMP_ADD_VARIANT_IN_BULK_IMPORT: "ws-comp-add-variant-in-bulk-import",
  WS_COMP_DELETE_VARIANT: "ws-comp-delete-variant",
  WS_COMP_CREATION: "ws-comp-creation",
  WS_COMP_CREATION_IN_BULK_IMPORT: "ws-comp-creation-in-bulk-import",
  WS_COMP_CHARACTER_LIMIT_UPDATE: "ws-comp-character-limit-update",
  TEXT_ITEM_CHARACTER_LIMIT_UPDATE: "text-item-character-limit-update",
  WS_COMP_DELETION: "ws-comp-deletion",
  // This is generated for a component that is moved to a folder as
  // part of a single-select "move to folder" action taken in the component
  // library.
  WS_COMP_MOVED_TO_FOLDER: "ws-comp-move-to-folder",
  // This is generated for each component that is moved to a folder as
  // part of a multi-select "move to folder" action taken in the component
  // library.
  WS_COMP_MOVED_TO_FOLDER_IN_BULK_OPERATION: "ws-comp-move-to-folder-in-bulk-operation",
  DUPES_WS_COMP_ATTACH: "dupes-ws-comp-attach",
  DUPES_WS_COMP_CREATION: "dupes-ws-comp-creation",
  DUPES_WS_COMP_VARIANT_UPDATE: "dupes-ws-comp-variant-update",
  DUPES_WS_COMP_BASE_TEXT_UPDATE: "dupes-ws-comp-base-text-update",
  DUPES_WS_COMP_EDIT: "dupes-ws-comp-edit", // generated by component updates via the API
  DUPES_WS_COMP_EDIT_NOTES: "dupes-ws-comp-edit-notes",
  DUPES_WS_COMP_EDIT_TAGS: "dupes-ws-comp-edit-tags",
  DUPES_WS_COMP_LIBRARY_EDIT: "dupes-ws-comp-library-edit", // generated by multi-select updates in the component library
  DUPES_WS_COMP_DETACH_AND_DELETE: "dupes-ws-comp-detach-and-delete",
  // This is generated once when a multi-select "move to folder" action is taken
  // in the component library.
  DUPES_WS_COMP_MOVED_TO_FOLDER: "dupes-ws-comp-move-to-folder",
  FOLDER_CREATION: "folder-creation",
  FOLDER_DELETION: "folder-deletion",
  FOLDER_UPDATE: "folder-update",
  PROJECT_MOVED_TO_FOLDER: "project-move-to-folder",
  COMPONENT_FOLDER_CREATION: "component-folder-creation",
  COMPONENT_FOLDER_DELETION: "component-folder-deletion",
  COMPONENT_FOLDER_UPDATE: "component-folder-update",
  COMPONENT_FOLDER_API_ID_EDIT: "component-folder-apiID-edit",
  COMPONENTS_IN_WORKSPACE_REGENERATED: "components-in-workspace-regenerated",
  POST_COMMENT: "post-comment",
  POST_REPLY: "post-reply",
  FRAME_APPLY_VARIANT: "frame-apply-variant",
  FRAME_REMOVE_APPLIED_VARIANT: "frame-remove-applied-variant",
  FRAME_ADD_VARIANT: "frame-add-variant",
  FRAME_DELETE_VARIANT: "frame-delete-variant",
  VARIANT_STATUS_CHANGED: "variant-status-changed",
  MULTI_VARIANT_STATUS_CHANGED: "multi-variant-status-changed",
  WS_COMP_VARIANT_STATUS_CHANGED: "ws-comp-variant-status-changed",
  VARIANT_CREATED: "variant-created",
  VARIANT_DELETED: "variant-deleted",
  VARIANT_NAME_EDIT: "variant-name-edit",
  VARIANT_DESCRIPTION_EDIT: "variant-description-edit",
  VARIANT_APIID_EDIT: "variant-apiID-edit",
  VARIANT_FOLDER_CREATION: "variant-folder-creation",
  VARIANT_FOLDER_DELETION: "variant-folder-deletion",
  VARIANT_FOLDER_UPDATE: "variant-folder-update",
  VARIANT_MOVED_TO_FOLDER_IN_BULK_OPERATION: "variant-move-to-folder-in-bulk-operation",
  VARIANT_MOVED_TO_FOLDER: "variant-move-to-folder",
  RICH_TEXT_ENABLED: "rich-text-enabled",
  RICH_TEXT_DISABLED: "rich-text-disabled",
  WORKSPACE_SETTINGS_COMPONENT_API_ID_PREVENT_MANUAL_EDITS: "workspace-setting-component-api-id-prevent-manual-edits",
  WORKSPACE_SETTINGS_COMPONENT_API_ID_GENERATE_ON_RENAME: "workspace-setting-component-api-id-regenerate-on-rename",
  WORKSPACE_SETTINGS_COMPONENT_API_ID_GENERATION_CONFIG: "workspace-setting-component-api-id-generation-config",
  WORKSPACE_SETTINGS_PROJECT_API_ID_GENERATION_CONFIG: "workspace-setting-project-api-id-generation-config",
  WORKSPACE_SETTINGS_PROJECT_API_ID_UPDATE_IDS_WHEN_GROUP_BLOCK_CHANGE:
    "workspace-setting-project-api-id-update-ids-when-group-block-change",
  WORKSPACE_SETTINGS_PROJECT_API_ID_PREVENT_MANUAL_EDITS: "workspace-setting-project-api-id-prevent-manual-edits",
  PROJECT_API_IDS_REGENERATED: "project-ids-regenerated",
  ALL_PROJECT_API_IDS_REGENERATED: "all-project-ids-regenerated",
  WEBHOOK_CREATED: "webhook-created",
  WEBHOOK_UPDATED: "webhook-updated",
  WEBHOOK_DELETED: "webhook-deleted",
  COMPONENTS_MERGED: "components_merged",
  COMPONENT_MERGED: "component_merged",
  COMPONENT_AUTO_ATTACH: "component-auto-attached",
  ...PLURAL_CHANGE_TYPES,
  ...VARIABLE_ACTUAL_CHANGE_TYPES,
  ...BRANCH_CHANGE_TYPES,
  ...DRAFTING_CHANGE_TYPES,
  ...AI_USAGE_TYPES,
  AB_INTEGRATION_ENABLED: "ab-integration-enabled",
  AB_INTEGRATION_DISABLED: "ab-integration-disabled",
  FRAME_AUTO_ATTACH: "frame-auto-imported",
  AUTO_ATTACH_COMPONENTS_ENABLED: "auto-attach-components-enabled",
  AUTO_ATTACH_COMPONENTS_DISABLED: "auto-attach-components-disabled",
  AUTO_IMPORT_FRAMES_ENABLED: "auto-import-frames-enabled",
  AUTO_IMPORT_FRAMES_DISABLED: "auto-import-frames-disabled",
};

interface ActualChangeData {
  rich_text_before?: RichText;
  [key: string]: any;
}

export interface IActualChange {
  _id: Types.ObjectId;
  parent?: Types.ObjectId | null;
  children?: Types.ObjectId[];
  user: string;
  userObjectId: Types.ObjectId | null;
  userId?: string;
  doc_name?: string | null;
  doc_id?: Types.ObjectId | null;
  workspace_ID: Types.ObjectId;
  variantId?: Types.ObjectId | null;
  entry_type: string;
  data?: ActualChangeData;
  dupe_comp_ids?: Types.ObjectId[];
  dupe_figma_node_ids?: string[];
  block_id?: Types.ObjectId;
  comp_id?: Types.ObjectId | null;
  frame_id?: Types.ObjectId;
  frame_name?: string;
  figma_node_id?: string | null;
  text_before?: string;
  text_after?: string;
  component_name?: string;
  variant_name?: string;
  date_time: Date;
  status?: (typeof TEXT_ITEM_STATUSES)[number] | null;
  pr_num?: number;
  ws_comp?: Types.ObjectId;
  comment_thread_id?: Types.ObjectId;
  dupe_ws_comp_ids?: Types.ObjectId[];
  isSample: boolean;
}

export type IFActualChange = FrontendSchema<IActualChange>;
