import { IFActualChange } from "@shared/types/ActualChange";
import { createHttpRequest } from "./lib/createHttpRequest";

export const getProjectActivity = createHttpRequest<
  {
    projectId: string;
    skip: number;
    limit: number;
  },
  { info: IFActualChange[] }
>({
  method: "get",
  getUrl: (args) => `/changes/doc/page/${args.projectId}?skip=${args.skip}&limit=${args.limit}`,
});
