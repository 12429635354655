import { useAtomValue, useSetAtom } from "jotai";
import { Suspense, useState } from "react";
import { fetchMoreProjectActivityAtom, projectActivityAtom, textItemActivityAtom } from "../stores/ActivityStore";
import { projectIdAtom } from "../stores/Project";
import { selectedSidebarPanelAtom, selectedTextItemIdsAtom, useSelectionStateUpdate } from "../stores/SelectionStore";

function Sidebar() {
  const projectId = useAtomValue(projectIdAtom);

  // Here is an example usage of consuming and updating selection state.
  const sidebarPanel = useAtomValue(selectedSidebarPanelAtom);
  const { updateSidebarPanel } = useSelectionStateUpdate();

  return (
    <div>
      <h1>Sidebar</h1>
      <h2>Panel: {sidebarPanel || ""}</h2>
      <button onClick={() => updateSidebarPanel("EDIT")}>Edit</button>
      <button onClick={() => updateSidebarPanel("ACTIVITY")}>Activity</button>
      <button onClick={() => updateSidebarPanel(null)}>None</button>
      {sidebarPanel === null && projectId && <ProjectActivity />}
      {sidebarPanel === "ACTIVITY" && (
        <Suspense fallback={<p>Loading activity...</p>}>
          <TextItemActivity />
        </Suspense>
      )}
    </div>
  );
}

function ProjectActivity() {
  const projectActivity = useAtomValue(projectActivityAtom);
  const [isFetchingProjectActivity, setIsFetchingProjectActivity] = useState(false);
  const fetchMoreProjectActivity = useSetAtom(fetchMoreProjectActivityAtom);

  return (
    <>
      <button
        onClick={async () => {
          setIsFetchingProjectActivity(true);
          await fetchMoreProjectActivity();
          setIsFetchingProjectActivity(false);
        }}
        disabled={isFetchingProjectActivity}
      >
        Load more activity
      </button>
      {(projectActivity.activity || []).map((activity) => (
        <div key={activity._id}>
          <h3>{activity.entry_type}</h3>
          <p>{activity.user}</p>
        </div>
      ))}
    </>
  );
}

function TextItemActivity() {
  const textItemActivity = useAtomValue(textItemActivityAtom);
  const selectedTextItems = useAtomValue(selectedTextItemIdsAtom);

  if (selectedTextItems.length !== 1) {
    return <p>Select a single text item to view activity</p>;
  }

  return (
    <>
      {textItemActivity.map((activity) => (
        <div key={activity._id}>
          <h3>{activity.entry_type}</h3>
          <p>{activity.user}</p>
        </div>
      ))}
    </>
  );
}

export { Sidebar };
