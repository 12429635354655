import * as DittoEvent from "@shared/ditto-events";
import { useDittoEventListener } from "@shared/ditto-events/frontend";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  projectActivityInitializeEffectAtom,
  refreshProjectActivityAtom,
  refreshTextItemActivityAtom,
} from "../stores/ActivityStore";
import { selectedSidebarPanelAtom, selectedTextItemIdsAtom } from "../stores/SelectionStore";

export function ActivityHandler() {
  useAtom(projectActivityInitializeEffectAtom);
  const refreshProjectActivity = useSetAtom(refreshProjectActivityAtom);
  const refreshTextItemActivity = useSetAtom(refreshTextItemActivityAtom);
  const sidebarPanel = useAtomValue(selectedSidebarPanelAtom);

  const selectedTextItemIds = useAtomValue(selectedTextItemIdsAtom);

  useDittoEventListener(
    DittoEvent.textItemsUpdated,
    // Will need to handle more events beyond textItemsUpdated
    async function handleTextItemsUpdated(e) {
      // Refreshing activity on new updates might not be the best tactic
      // but we can optimize this later.
      if (sidebarPanel === null) {
        refreshProjectActivity();
        return;
      }

      const hasOneSelectedTextItem = selectedTextItemIds.length === 1;
      // Have to do this because of https://linear.app/dittowords/issue/DIT-7815/fix-usedittoevents-data-type
      // @ts-ignore
      if (sidebarPanel === "ACTIVITY" && hasOneSelectedTextItem && e.textItemIds.includes(selectedTextItemIds[0])) {
        refreshTextItemActivity();
      }
    },
    []
  );

  return null;
}
