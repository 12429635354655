/**
 * This file is shared between the front-end and the back-end. It's important
 * that it only imports code that can be safely used on both. Importing modules
 * with side effects that expect a node environment or browser environment
 * will cause errors.
 */
import { REDIS_CHANNELS } from "@shared/common/constants";
import * as SegmentEvents from "@shared/segment-event-names";
import { ZComponentCreationType } from "@shared/types/ActualChange";
import { ZComponentNamesMap, ZUpdatedComponent } from "@shared/types/Component";
import { ZActualComponentStatus } from "@shared/types/TextItem";
import { ZUser } from "@shared/types/User";
import { ZCreatableId } from "@shared/types/lib";
import { z } from "zod";
import slackMessageTypes from "../../services/slack/slackMessageTypes";
import { COMPONENT_ATTACHED_TO_TEXT_ITEMS } from "../segment-event-names";
import { createDittoEvent, filterOutSampleDataComponentEvents, filterWebhookEventsByComponentFolder } from "./lib";

export const NEW_APP_VERSION_TYPES = {
  WEB: "web",
  PLUGIN_FIGMA: "plugin-figma",
};

/**
 * Emit when a new app version is released and requries a reload
 * from the user.
 */
const ZNewAppVersionReleased = z.object({
  Data: z.object({
    app: z.union([z.literal(NEW_APP_VERSION_TYPES.WEB), z.literal(NEW_APP_VERSION_TYPES.PLUGIN_FIGMA)]),
  }),
});
export const newAppVersionReleased = createDittoEvent({
  name: "NewAppVersionReleased",
  data: ZNewAppVersionReleased.shape.Data,
  targets: [
    {
      type: "websocket",
      // Send to everyone
      identifyClient: () => true,
    },
  ],
});

/**
 * Emit when any set of fields on one or more components is updated.
 */
const ZComponentsUpdated = z.object({
  Data: z.object({
    senderUserId: z.string(),
    fromPlugin: z.boolean().optional(),
    components: z.array(ZUpdatedComponent),
    deletedComponentIds: z.array(ZCreatableId),
    workspaceId: ZCreatableId,
  }),
});
export const componentsUpdated = createDittoEvent({
  name: "ComponentUpdated",
  data: ZComponentsUpdated.shape.Data,
  targets: [
    {
      type: "websocket",
      identifyClient: (webSocketClient, data) =>
        webSocketClient.workspaceCompSubscription?.toString() === data.workspaceId.toString(),
    },
  ],
});

const ZComponentTextChange = z.object({
  data: z.object({
    workspaceId: ZCreatableId,
    componentId: ZCreatableId,
    componentApiId: z.string(),
    folderId: ZCreatableId.nullable(),
    folderApiId: z.string().nullable(),
    textBefore: z.string(),
    textAfter: z.string(),
  }),
  webhookData: z.object({
    componentId: ZCreatableId, // this should be the component's API ID
    folderId: z.string().nullable(), // this should be the folder's API ID
    textBefore: z.string(),
    textAfter: z.string(),
  }),
});
export const componentTextChange = createDittoEvent({
  name: "Component_TextChange",
  data: ZComponentTextChange.shape.data,
  targets: [
    {
      type: "webhook",
      getContext: (d) => ({
        workspaceId: d.workspaceId,
        componentId: d.componentId,
      }),
      filterEvents: async (data, filters, context) =>
        (await filterWebhookEventsByComponentFolder({ folderId: data.folderId }, filters, context)) &&
        (await filterOutSampleDataComponentEvents({ componentId: data.componentId }, filters, context)),
      formatWebhookData: (d): z.infer<typeof ZComponentTextChange>["webhookData"] => ({
        componentId: d.componentApiId,
        folderId: d.folderApiId,
        textBefore: d.textBefore,
        textAfter: d.textAfter,
      }),
    },
  ],
});
const ZComponentStatusChange = z.object({
  data: z.object({
    workspaceId: ZCreatableId,
    componentId: ZCreatableId,
    componentApiId: z.string(),
    folderId: ZCreatableId.nullable(),
    folderApiId: z.string().nullable(),
    statusBefore: z.string(),
    statusAfter: z.string(),
  }),
  webhookData: z.object({
    componentId: ZCreatableId, // this should be the component's API ID
    folderId: z.string().nullable(), // this should be the folder's API ID
    statusBefore: z.string(),
    statusAfter: z.string(),
  }),
});
export const componentStatusChange = createDittoEvent({
  name: "Component_StatusChange",
  data: ZComponentStatusChange.shape.data,
  targets: [
    {
      type: "webhook",
      getContext: (d) => ({
        workspaceId: d.workspaceId,
        componentId: d.componentId,
      }),
      filterEvents: async (data, filters, context) =>
        (await filterWebhookEventsByComponentFolder({ folderId: data.folderId }, filters, context)) &&
        (await filterOutSampleDataComponentEvents({ componentId: data.componentId }, filters, context)),
      formatWebhookData: (d): z.infer<typeof ZComponentStatusChange>["webhookData"] => ({
        componentId: d.componentApiId,
        folderId: d.folderApiId,
        statusBefore: d.statusBefore,
        statusAfter: d.statusAfter,
      }),
    },
  ],
});
const ZComponentDeveloperIdChange = z.object({
  data: z.object({
    workspaceId: ZCreatableId,
    componentId: ZCreatableId,
    componentApiId: z.string(),
    folderId: ZCreatableId.nullable(),
    folderApiId: z.string().nullable(),
    apiIdBefore: z.string(),
    apiIdAfter: z.string(),
  }),
  webhookData: z.object({
    folderId: z.string().nullable(), // this should be the folder's API ID
    componentIdBefore: z.string(),
    componentIdAfter: z.string(),
  }),
});
export const componentDeveloperIdChange = createDittoEvent({
  name: "Component_IdChange",
  data: ZComponentDeveloperIdChange.shape.data,
  targets: [
    {
      type: "webhook",
      getContext: (d) => ({
        workspaceId: d.workspaceId,
        componentId: d.componentId,
      }),
      filterEvents: async (data, filters, context) =>
        (await filterWebhookEventsByComponentFolder({ folderId: data.folderId }, filters, context)) &&
        (await filterOutSampleDataComponentEvents({ componentId: data.componentId }, filters, context)),
      formatWebhookData: (d): z.infer<typeof ZComponentDeveloperIdChange>["webhookData"] => ({
        folderId: d.folderApiId,
        componentIdBefore: d.apiIdBefore,
        componentIdAfter: d.apiIdAfter,
      }),
    },
  ],
});

const ZComponentCreated = z.object({
  data: z.object({
    workspaceId: ZCreatableId,
    userId: z.string(),
    creationType: ZComponentCreationType,
    componentId: ZCreatableId,
    componentApiId: z.string(),
    folderId: ZCreatableId.nullable(),
    folderApiId: z.string().nullable(),
    name: z.string(),
    text: z.string(),
    status: z.string(),
    notes: z.string(),
    tags: z.array(z.string()),
  }),
  webhookData: z.object({
    componentId: z.string(),
    // this should be the folder's API ID
    folderId: z.string().nullable(),
    name: z.string(),
    text: z.string(),
    status: z.string(),
    notes: z.string(),
    tags: z.array(z.string()),
  }),
  segmentData: z.object({
    component_id: z.string(),
    action: ZComponentCreationType,
  }),
});
export const componentCreated = createDittoEvent({
  name: "Component_Creation",
  data: ZComponentCreated.shape.data,
  targets: [
    {
      type: "webhook",
      getContext: (d) => ({
        workspaceId: d.workspaceId,
        componentId: d.componentId,
      }),
      filterEvents: async (data, filters, context) =>
        (await filterWebhookEventsByComponentFolder({ folderId: data.folderId }, filters, context)) &&
        (await filterOutSampleDataComponentEvents({ componentId: data.componentId }, filters, context)),
      formatWebhookData: (d): z.infer<typeof ZComponentCreated>["webhookData"] => ({
        folderId: d.folderApiId,
        componentId: d.componentApiId,
        name: d.name,
        text: d.text,
        status: d.status,
        notes: d.notes,
        tags: d.tags,
      }),
    },
    {
      type: "segment",
      segmentEventName: "Component Created",
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.userId }),
      formatSegmentData: (d): z.infer<typeof ZComponentCreated>["segmentData"] => ({
        component_id: d.componentId.toString(),
        action: d.creationType,
      }),
    },
    {
      type: "websocket",
      identifyClient: (webSocketClient, data) =>
        webSocketClient.workspaceCompSubscription?.toString() === data.workspaceId.toString(),
    },
  ],
});

const ZComponentsCreated = z.object({
  data: z.object({
    componentIds: z.array(ZCreatableId),
    componentNamesMap: ZComponentNamesMap,
    workspaceId: ZCreatableId,
    userId: z.string(),
    creationType: ZComponentCreationType,
  }),
  segmentData: z.object({
    component_ids: z.array(z.string()),
    action: ZComponentCreationType,
  }),
});
export const componentsCreated = createDittoEvent({
  name: "Components_Creation",
  data: ZComponentsCreated.shape.data,
  targets: [
    {
      type: "segment",
      segmentEventName: "Components Created",
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.userId }),
      formatSegmentData: (d): z.infer<typeof ZComponentsCreated>["segmentData"] => ({
        component_ids: d.componentIds.map((id) => id.toString()),
        action: d.creationType,
      }),
    },
    {
      type: "websocket",
      identifyClient: (webSocketClient, data) =>
        webSocketClient.workspaceCompSubscription?.toString() === data.workspaceId.toString(),
    },
  ],
});

const ZComponentDeleted = z.object({
  data: z.object({
    workspaceId: ZCreatableId,
    componentId: ZCreatableId,
    componentApiId: z.string(),
    folderId: ZCreatableId.nullable(),
    folderApiId: z.string().nullable(),
    name: z.string(),
  }),
  webhookData: z.object({
    componentId: z.string(),
    folderId: z.string().nullable(), // this should be the folder's API ID
    name: z.string(),
  }),
});
export const componentDeleted = createDittoEvent({
  name: "Component_Deletion",
  data: ZComponentDeleted.shape.data,
  targets: [
    {
      type: "webhook",
      getContext: (d) => ({
        workspaceId: d.workspaceId,
        componentId: d.componentId,
      }),
      filterEvents: async (data, filters, context) =>
        (await filterWebhookEventsByComponentFolder({ folderId: data.folderId }, filters, context)) &&
        (await filterOutSampleDataComponentEvents({ componentId: data.componentId }, filters, context)),
      formatWebhookData: (d): z.infer<typeof ZComponentDeleted>["webhookData"] => ({
        folderId: d.folderApiId,
        componentId: d.componentApiId,
        name: d.name,
      }),
    },
  ],
});

// MARK: - Text Item Events

const ZTextITemsUpdated = z.object({
  data: z.object({
    from: z.enum(["web_app", "figma_plugin", "unknown"]),
    ids: z.array(z.string()),
    projectId: z.string(),
    projectName: z.string(),
    updates: z.object({
      assignee: z
        .object({
          _id: z.string().nullable(),
          newAssigneeUserId: z.string().nullable(),
          newAssigneeName: z.string().nullable(),
        })
        .optional(),
      tags: z
        .object({
          tagsAdded: z.array(z.string()),
          tagsDeleted: z.array(z.string()),
        })
        .optional(),
      status: z
        .object({
          status: ZActualComponentStatus,
          textItemText: z.string(),
        })
        .optional(),
      characterLimit: z.number().nullable().optional(),
    }),
    user: z.object({
      userId: z.string(),
      name: z.string(),
    }),
    workspaceId: z.string(),
  }),
});
export const textItemsUpdated = createDittoEvent({
  name: "TextItemsUpdated",
  data: ZTextITemsUpdated.shape.data,
  targets: [
    {
      type: "segment",
      segmentEventName: SegmentEvents.TEXT_ITEMS_STATUS_CHANGED,
      filterEvents: async (data) => data.updates.status !== undefined,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.user.userId }),
      formatSegmentData: (d) => ({
        doc_id: d.projectId,
        text_item_ids: d.ids,
        status: d.updates.status,
        application: d.from,
      }),
    },
    {
      type: "segment",
      segmentEventName: SegmentEvents.MULTIPLE_COMPS_TAGS_UPDATED,
      filterEvents: async (data) => data.updates.tags !== undefined,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.user.userId }),
      formatSegmentData: (d) => ({
        doc_id: d.projectId,
        workspace_id: d.workspaceId,
        num_comps: d.ids.length,
        from: d.from,
      }),
    },
    {
      type: "segment",
      segmentEventName: SegmentEvents.TEXT_ITEM_ASSIGNED,
      filterEvents: async (data) => data.updates.assignee !== undefined,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.user.userId }),
      formatSegmentData: (d) => ({
        project_id: d.projectId,
        text_item_id: d.ids[0],
        assignee: d.updates.assignee?.newAssigneeUserId,
        count: d.ids.length,
      }),
    },
    {
      type: "segment",
      segmentEventName: SegmentEvents.TEXT_ITEMS_ASSIGNED,
      filterEvents: async (data) => data.updates.tags !== undefined,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.user.userId }),
      formatSegmentData: (d) => ({
        project_id: d.projectId,
        text_item_ids: d.ids,
        assignee: d.updates.assignee?.newAssigneeUserId,
        count: d.ids.length,
      }),
    },
    {
      type: "slack",
      getContext: (d) => ({ workspaceId: d.workspaceId, projectId: d.projectId }),
      filterEvents: async (d) => !!d.updates.status,
      formatSlackData: async (d) => {
        return slackMessageTypes.componentStatusChanged(
          d.user.name,
          d.updates.status!.status,
          d.updates.status!.textItemText,
          d.projectId,
          d.projectName,
          false,
          d.ids.length
        );
      },
    },
    {
      type: "slack",
      getContext: (d) => ({ workspaceId: d.workspaceId, projectId: d.projectId }),
      filterEvents: async (d) => !!d.updates.assignee?._id,
      formatSlackData: async (d) => {
        return slackMessageTypes.componentAssigned(
          d.user.name,
          d.updates.assignee?.newAssigneeName || "",
          "",
          d.projectId,
          d.projectName,
          false,
          d.ids.length
        );
      },
    },
    {
      type: "websocket",
      channel: REDIS_CHANNELS.TEXT_ITEM_UPDATE_CHANNEL,
      identifyClient: (webSocketClient, data) =>
        webSocketClient.workspaceId?.toString() === data.workspaceId.toString(),
      formatWebsocketData: async (d) => ({
        docId: d.projectId,
        textItemIds: d.ids,
        senderUserId: d.user.userId,
        fromPlugin: d.from === "figma_plugin",
        // backwards compatibility with outdated clients / backend processes still rolling over
        components: [],
      }),
    },
  ],
});

// MARK: - Types For Background Jobs Events

const ZBaseBackgroundJob = z.object({
  id: z.number().or(z.string()),
  jobName: z.string(),
  workspaceId: z.string(),
});

const ZBackgroundJobCompleted = ZBaseBackgroundJob.extend({
  status: z.literal("completed"),
  returnValue: z.any(),
});

const ZBackgroundJobFailed = ZBaseBackgroundJob.extend({
  status: z.literal("failed"),
  failedReason: z.string().optional(),
});

const ZBackgroundJob = z.discriminatedUnion("status", [ZBackgroundJobCompleted, ZBackgroundJobFailed]);

/**
 * Emit when a background job is updated.
 */
export const backgroundJobUpdated = createDittoEvent({
  name: "BackgroundJobUpdated",
  data: ZBackgroundJob,
  targets: [
    {
      type: "websocket",
      identifyClient: (webSocketClient, data) => webSocketClient.workspaceId === data.workspaceId,
    },
  ],
});

export const fetchedProjectNamesViaApi = createDittoEvent({
  name: "Fetched project names via API",
  data: z.object({
    apiVersion: z.number(),
    workspaceId: ZCreatableId,
    userId: z.string(),
    isDittoCLIRequest: z.boolean(),
  }),
  targets: [
    {
      type: "segment",
      getContext: (d) => ({
        workspaceId: d.workspaceId,
        userId: d.userId,
      }),
    },
  ],
});

export const firstApiFetch = createDittoEvent({
  name: "First API Fetch",
  data: z.object({
    workspaceId: ZCreatableId,
    userId: z.string(),
    timeSinceWorkspaceCreated: z.number(),
  }),
  targets: [
    {
      type: "segment",
      getContext: (d) => ({
        workspaceId: d.workspaceId,
        userId: d.userId,
      }),
    },
  ],
});

export const firstDeveloperJoined = createDittoEvent({
  name: "First Developer Joined",
  data: z.object({
    workspaceId: ZCreatableId,
    userId: z.string(),
    timeSinceWorkspaceCreated: z.number(),
  }),
  targets: [
    {
      type: "segment",
      getContext: (d) => ({
        workspaceId: d.workspaceId,
        userId: d.userId,
      }),
    },
  ],
});

export const onboardingResponses = createDittoEvent({
  name: "Onboarding Responses",
  data: z.object({
    user: ZUser.pick({
      workspaceId: true,
      userId: true,
      email: true,
    }),
    referralSource: z.string(),
    dittoUseCaseResponses: z.string(),
  }),
  targets: [
    {
      type: "segment",
      getContext: (d) => ({
        workspaceId: d.user.workspaceId,
        userId: d.user.userId,
      }),
    },
  ],
});

export const ZConcurrentUserListUpdateData = z.object({
  workspaceId: z.string(),
  pageKey: z.string(),
  users: z.array(
    z.object({
      _id: z.string(),
      name: z.string(),
      picture: z.string().optional().nullable(),
    })
  ),
});

export type ConcurrentUserListUpdateData = z.infer<typeof ZConcurrentUserListUpdateData>;

export const concurrentUserListUpdate = createDittoEvent({
  name: "Concurrent User List Update",
  data: ZConcurrentUserListUpdateData,
  targets: [
    {
      type: "websocket",
      // emit the event to everyone in the workspace; front-end clients can decide whether or not
      // they care about a given event according to the `pageKey` value
      identifyClient: (webSocketClient, data) =>
        webSocketClient.workspaceId?.toString() === data.workspaceId.toString(),
    },
  ],
});

export const ZGroupsUnlinked = z.object({
  groups: z.array(z.string()),
  docId: z.string(),
});

export const groupsUnlinked = createDittoEvent({
  name: "Groups have been unlinked",
  data: ZGroupsUnlinked,
  targets: [
    {
      type: "websocket",
      identifyClient: (webSocketClient, data) => webSocketClient.docSubscription?.toString() === data.docId.toString(),
    },
  ],
});

export const ZGroupsImported = z.object({
  newFrameIdsMap: z.record(z.string()),
  docId: z.string(),
});

export const groupsImported = createDittoEvent({
  name: "GroupsImported",
  data: ZGroupsImported,
  targets: [
    {
      type: "websocket",
      identifyClient: (webSocketClient, data) => webSocketClient.docSubscription?.toString() === data.docId.toString(),
    },
  ],
});

export const ZBlockEvent = z.object({
  blockId: z.string(),
  groupId: z.string(),
  newName: z.string(),
  documentId: z.string(),
});

export const newBlock = createDittoEvent({
  name: "New block created",
  data: ZBlockEvent,
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) => websocketClient.docSubscription?.toString() === data.documentId,
    },
  ],
});

export const updateBlock = createDittoEvent({
  name: "Update block",
  data: ZBlockEvent,
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) => websocketClient.docSubscription?.toString() === data.documentId,
    },
  ],
});

// Order matters here!
export const ImportProgressSteps = [
  "frontend-start",
  "request-sent",
  "job-start",
  "figma-data-fetched",
  "save-project-get-doc",
  "frame-check-complete",
  "generate-component-map-complete",
  "generate-update-groups-complete",
  "check-unknown-figma-nodes-complete",
  "generate-component-upsert-commands-complete",
  "resync-complete",
  "job-end",
  "finished",
] as const;
export const ZImportProgressSteps = z.enum(ImportProgressSteps);

export const ZImportProgress = z.object({
  step: ZImportProgressSteps,
  // This is the sub, "userId" on the Users collection
  userId: z.string(),
  importJobId: z.string(),
});

export const importProgress = createDittoEvent({
  name: "Import progress",
  data: ZImportProgress,
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) => websocketClient.userId === data.userId,
    },
  ],
});

export const ZAttachMethod = z.enum(["import", "auto-component", "manual", "figma-variables", "swap"]);

export const ZComponentAttachedToTextItems = z.object({
  userId: z.string(),
  projectId: z.string(),
  workspaceId: z.string(),
  componentId: z.string(),
  textItemIds: z.array(z.string()),
  action: ZAttachMethod,
});

export const componentAttachedToTextItems = createDittoEvent({
  name: "Component attached to text items",
  data: ZComponentAttachedToTextItems,
  targets: [
    {
      type: "websocket",
      identifyClient: (webSocketClient, data) =>
        webSocketClient.workspaceId?.toString() === data.workspaceId.toString(),
    },
    {
      type: "segment",
      segmentEventName: COMPONENT_ATTACHED_TO_TEXT_ITEMS,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.userId }),
      formatSegmentData: (d) => ({
        text_item_ids: d.textItemIds,
        component_id: d.componentId,
        project_id: d.projectId,
        action: d.action,
      }),
    },
  ],
});

export const ZGroupUpdatedEvent = z.object({
  groupId: z.string(),
  documentId: z.string(),
});

export const groupUpdated = createDittoEvent({
  name: "Group updated",
  data: ZGroupUpdatedEvent,
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) => websocketClient.docSubscription?.toString() === data.documentId,
    },
  ],
});

export const ZAutoAttachComponentsStarted = z.object({
  projectId: z.string(),
  workspaceId: z.string(),
});

export const autoAttachComponentsStarted = createDittoEvent({
  name: "Auto Attach Components Started",
  data: ZAutoAttachComponentsStarted,
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) => websocketClient.workspaceId === data.workspaceId,
    },
  ],
});

export const ZAutoAttachComponentsFinished = z.object({
  projectId: z.string(),
  workspaceId: z.string(),
  textItemsAttached: z.array(z.string()),
});

export const autoAttachComponentsFinished = createDittoEvent({
  name: "Auto Attach Components Finished",
  data: ZAutoAttachComponentsFinished,
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) => websocketClient.workspaceId === data.workspaceId,
    },
  ],
});

export const ZComponentRename = z.object({
  componentId: ZCreatableId,
  newNames: z.object({
    groupName: z.string(),
    blockName: z.string(),
    componentName: z.string(),
  }),
  oldNames: z.object({
    groupName: z.string(),
    blockName: z.string(),
    componentName: z.string(),
  }),
});

export type IComponentRename = z.infer<typeof ZComponentRename>;

export const ComponentRenameMap = z.record(z.string(), ZComponentRename);

export type IComponentRenameMap = z.infer<typeof ComponentRenameMap>;

export const componentsRenamed = createDittoEvent({
  name: "ComponentsRenamed",
  data: z.object({
    workspaceId: ZCreatableId,
    renamesMap: ComponentRenameMap,
  }),
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) => websocketClient.workspaceId === data.workspaceId,
    },
  ],
});

// MARK: - Figma Cache Ditto Events

export const figmaCacheRefreshStarted = createDittoEvent({
  name: "Figma Cache Refresh Started",
  data: z.object({
    fileId: z.string(),
    branchId: z.string().nullable(),
    workspaceId: ZCreatableId,
  }),
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) => websocketClient.workspaceId === data.workspaceId,
    },
  ],
});

export const figmaCacheRefreshCompleted = createDittoEvent({
  name: "Figma Cache Refresh Completed",
  data: z.object({
    fileId: z.string(),
    branchId: z.string().nullable(),
    workspaceId: ZCreatableId,
    didUpdate: z.boolean(),
    lastModified: z.date(),
  }),
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) => websocketClient.workspaceId === data.workspaceId,
    },
  ],
});
